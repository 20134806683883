import styled from 'styled-components';
import { device } from '../../layout/device';
import colors from '../../layout/globalColors';
import { Wrapper } from '../../layout/global-style';

export const ServicesWrapper = styled(Wrapper)`
  padding-bottom: 10rem;

  @media ${device.laptop} {
    padding-top: 4rem;
  }

  @media ${device.desktopS} {
    padding-top: 0rem;
  }
`;

export const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.25rem;

  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.laptopL} {
    grid-gap: 2rem;
  }
`;

export const Card = styled.div.attrs(() => ({ tabIndex: 0 }))`
  background: linear-gradient(
    180deg,
    ${colors.spaceCadetDark},
    ${colors.spaceCadet2Dark}
  );
  border-radius: 1rem;
  max-width: 32rem;
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 2.25rem;
`;

export const CardImgBox = styled.div`
  display: flex;
  justify-content: center;

  & img {
    transform: translateY(-6.25rem);
    margin-bottom: -4.5rem;
  }

  @media ${device.desktopS} {
    img {
      min-width: 400px;
    }
  }
`;

export const CardBody = styled.div`
  text-align: center;
  padding: 0 1.8rem 3rem;

  @media ${device.laptopL} {
    padding: 0 3rem 3rem;
  }
  @media ${device.desktopS} {
    padding: 0 4rem 3rem;
    font-size: 1.1rem;
    line-height: 1.7;
  }
`;

export const CardTitle = styled.h3`
  font-size: 1.31rem;
  font-weight: 500;
  color: ${colors.cadetBlue};
  margin-bottom: 1rem;
`;

export const CardPara = styled.div`
  color: ${colors.lightGrey};
`;
