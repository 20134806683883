import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { TeamServiceWrapper } from './style';
import Title from '../Title';
import Button from '../Buttons';

function TeamService() {
  return (
    <TeamServiceWrapper>
      <div className="container">
        <div className="team-service-grid">
          <Title title="Team as a Service" />
          <div className="img-box" tabIndex="0">
            <StaticImage
              src="../../../static/img/optimum/Optimum-Team-As-A-Service.jpg"
              alt="Two Team members discussing about the layout of an app"
            />
          </div>
          <div className="content" tabIndex="0">
            <p>
              Our firm offers highly efficient offshore development
              team-as-a-service. We&apos;ve spent years learning the ins and
              outs of the individual recruitment process, the best ways to
              interview, and how to implement pay scales and taxation structures
              so you don&apos;t have to.
            </p>
            <p>
              Have a project that needs to get started quickly? We can set up a
              professional development team of five in less than a week.
            </p>
            <Button
              onClick={() => scrollTo('#contactForm')}
              btnText="Get a Proposal"
              btnType="rounded-outline"
            />
          </div>
        </div>
      </div>
    </TeamServiceWrapper>
  );
}

export default TeamService;
