import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import Title from '../Title';
import { ContactusWrapper } from './style';

import { Toaster } from 'react-hot-toast';
import ContactForm from './ContactForm';

const index = () => {
  return (
    <ContactusWrapper id="contactForm">
      <Toaster />
      <div className="container">
        <Title
          title="Get in Touch"
          desc="Engage with us for trustworthy, high-quality JavaScript and mobile app development. Please enter your details below, and one of our consultants will get back to you within forty-eight hours. "
          align="center"
        />

        <div className="contact-tabs">
          <Tabs aria-label="Contact Navbar" defaultActiveKey="tab1">
            <Tab eventKey="tab1" title="New Project">
              <ContactForm
                formType="new_project"
                askCompanyName={true}
                btnText="Send Enquiry"
              />
            </Tab>
            <Tab eventKey="tab2" title="Joining Our Team">
              <ContactForm
                formType="join_team"
                askLinkedinURL={true}
                btnText="Send Application"
              />
            </Tab>
            <Tab eventKey="tab3" title="General Enquiries">
              <ContactForm btnText="Reach Out" />
            </Tab>
          </Tabs>
        </div>
      </div>
    </ContactusWrapper>
  );
};

export default index;
