import * as React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../layout/layout';
import SEO from '../components/SEO';
import { ROUTES } from '../config/constants';

import Hero from '../components/Hero';
import Services from '../components/Services';
import TeamService from '../components/TeamService';
import Achievements from '../components/Achievements';
import Blogs from '../components/Blogs';
import ContactUs from '../components/ContactUs';

const IndexPage = ({ data }) => (
  <Layout pageTitle="Experts Carving Your Way To Digital Success.">
    <SEO title={ROUTES.home.title} description={ROUTES.home.desc} />
    <Hero pageTitle="Bespoke Tech, Trusted Partner">
      <p>
        At Optimum Futurist, we redefine your business prospects by delivering
        top-notch, affordable IT consulting and bespoke digital systems.
      </p>
      <p>
        Our skilled team, rooted in Nepal, embraces innovative software
        engineering and robust enterprise solutions, providing a distinct edge
        to your business.
      </p>
    </Hero>
    <Services />
    <TeamService />
    <Achievements
      title="Achievements"
      desc="We've successfully delivered dozens of projects with outstanding service and are committed to providing you nothing less than the best."
    />
    <Blogs data={data.Blogs} />
    <ContactUs />
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;

export const query = graphql`
  {
    Casestudies: allMarkdownRemark(
      limit: 2
      filter: { fileAbsolutePath: { regex: "/case-studies/.*.md$/" } }
    ) {
      nodes {
        frontmatter {
          date
          title
          showCaseImage
          companyLogo
          keywords
          website
        }
        fields {
          slug
        }
      }
    }
    Blogs: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog-post/.*.md$/" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 2
    ) {
      nodes {
        frontmatter {
          date
          title
          cover
          keywords
        }
        excerpt
        fields {
          slug
        }
      }
    }
  }
`;
