import React from 'react';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';

import Title from '../Title';
import Button from '../Buttons';
// import CtaLink from '../Buttons/style';
import { HeroWrapper, ImgGroup, BgLine } from './style';
import { StaticImage } from 'gatsby-plugin-image';
import { CgChevronRightO } from '@react-icons/all-files/cg/CgChevronRightO';

const Hero = ({ pageTitle, children }) => {
  return (
    <HeroWrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg">
            <Title
              title={pageTitle}
              headingOf="page"
              mbtablet="4rem"
              mblaptop="2rem"
            />
            {children}
            <br />
            <br />
            <Button
              onClick={() => scrollTo('#contactForm')}
              btnText="Hire us"
              btnType="rounded-outline"
              icon={<CgChevronRightO />}
            />
          </div>
          <div className="col-lg">
            <ImgGroup>
              <StaticImage
                className="img-top"
                src="../../../static/img/optimum/home-professional.webp"
                alt="A team of three people playing tower game showcaswing teamwork and trust"
                tabIndex="0"
                loading="eager"
              />
              <StaticImage
                className="img-mid"
                src="../../../static/img/optimum/Optimum-Futurist-Professionals-Two-People.jpg"
                alt="A man working on laptop and pointing to screen"
                tabIndex="0"
                loading="eager"
              />
              <StaticImage
                className="img-bottom"
                src="../../../static/img/optimum/Optimum-Futurist-Work-Team-Background.jpg"
                alt="perosn working on laptop only hand and laptop is visible"
                tabIndex="0"
                loading="eager"
              />
            </ImgGroup>
          </div>
        </div>
      </div>

      <BgLine>
        <img src="/img/heroLine.svg" width="1920" height="422" alt="" />
      </BgLine>
    </HeroWrapper>
  );
};

Hero.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.node,
};

export default Hero;
