import styled from 'styled-components';
import { device, size } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const TeamServiceWrapper = styled(Wrapper)`
  max-width: ${size.desktop};

  & .img-box img {
    margin-bottom: 5rem;
    max-width: 100%;
    border-radius: 1rem;
    object-fit: cover;
    max-height: 24rem;
  }

  .content {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 2rem;

    button {
      display: none;
      margin-top: 2rem;
    }
  }

  @media ${device.tablet} {
    .content button {
      display: inline-block;
    }

    & .img-box img {
      margin-bottom: 0rem;
    }
  }

  @media ${device.laptop} {
    .team-service-grid {
      display: grid;
      grid-template-columns: 5fr 7fr;
      grid-gap: 3rem;
    }

    .content {
      font-size: 1.1rem;
      margin-top: -5rem;
    }

    .img-box {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    & .img-box > div {
      position: absolute;
      right: 0;
      min-width: 45%;
      max-width: 800px;
    }

    & .img-box img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: 24rem;
      margin-bottom: 0;
    }
  }

  @media ${device.laptopL} {
    padding: 2rem 1.25rem 16rem;

    & .img-box img {
      max-height: 24rem;
    }

    .content button {
      margin-top: 4rem;
    }
  }

  @media ${device.desktopS} {
    .team-service-grid {
      padding-left: 4rem;
    }

    .team-service-grid h2 {
      margin-top: 2rem;
    }

    .content {
      padding-right: 4rem;
    }

    & .img-box > div {
      width: 100%;
    }

    & .img-box img {
      max-height: 30rem;
    }
  }

  @media ${device.desktop} {
    & img {
      max-width: 60rem;
    }
  }
`;
