import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const BlogsWrapper = styled(Wrapper)`
  padding: 2rem 1.25rem 6rem;
`;

export const BlogsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.2rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopL} {
    grid-gap: 2rem;
  }
`;

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background: linear-gradient(
    180deg,
    ${colors.spaceCadet},
    ${colors.spaceCadet2}
  );
  box-shadow: 0 0.9rem 50rem rgba(0, 0, 0, 0.15);
  margin: 3rem 0 0;

  @media ${device.laptop} {
    margin: 0;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.2rem;
  font-size: 0.9rem;

  & time {
    font-weight: 500;
    text-align: right;
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }

  & span {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    padding-right: 1rem;
  }

  @media ${device.laptopL} {
    padding: 1.4rem 1.4rem 1rem;
  }
`;

export const CardImgBox = styled.div`
  display: flex;

  & img {
    height: auto;
    width: 100%;
    object-fit: cover;

    @media ${device.tablet} {
      height: 200px;
    }

    @media ${device.laptopL} {
      height: 300px;
    }
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;

  & button,
  & a {
    margin-top: 2rem;
    text-transform: uppercase;
    color: ${colors.white};
    font-size: 0.9rem;
  }

  a svg {
    font-size: 1.25rem;
    color: ${colors.white};
  }

  & h3 {
    font-size: 1.2rem;

    @media ${device.laptopL} {
      font-size: 1.8rem;
      margin: 0rem 0 1.2rem;
    }
  }

  & p {
    margin: 0rem 0 1.5rem;
    color: ${colors.grey};
  }

  @media ${device.laptop} {
    padding: 2rem;
    & a {
      margin-top: 0;
      font-size: 0.9rem;
    }

    & p {
      font-size: 1.2rem;
      line-height: 1.7;
      margin: 0.5rem 0 2.5rem;
    }
  }
`;
