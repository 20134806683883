import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'gatsby';
import { CgChevronRightO } from '@react-icons/all-files/cg/CgChevronRightO';
var dayjs = require('dayjs');

import { CtaLink } from '../Buttons/style';
import Title from '../Title';
import { CtaLinkRoundedOutline } from '../Buttons/style';

import {
  BlogsWrapper,
  BlogsGrid,
  Card,
  CardInfo,
  CardImgBox,
  CardBody,
} from './style';

const BlogCard = (props) => {
  return (
    <Card>
      <CardInfo>
        <span>{props.tag}</span>
        <time dateTime={props.postDate}>{props.postDate}</time>
      </CardInfo>
      <CardImgBox>
        <Link to={props.link} aria-label={props.title}>
          <img src={props.img} alt={props.title} />
        </Link>
      </CardImgBox>
      <CardBody>
        <h3 tabIndex="0">{props.title}</h3>
        <p tabIndex="0">{props.desc}</p>
        <CtaLink to={props.link} aria-label={props.title}>
          Read More
          <CgChevronRightO />
        </CtaLink>
      </CardBody>
    </Card>
  );
};

const index = ({ data }) => {
  return (
    <BlogsWrapper>
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-3">
            <Title
              title="Blog"
              desc="Our acclaimed blog provides free guides on software setup, automation, and simple ways to take your company to the digital age."
            />
            <CtaLinkRoundedOutline to="/blogs">See All</CtaLinkRoundedOutline>
          </div>

          <div className="col-xl-8 col-lg-9">
            <BlogsGrid>
              {!data.nodes ? (
                <div>No data - `./component/Work/index.js`</div>
              ) : (
                data.nodes.map((node) => {
                  const { cover, title, keywords, date } = node.frontmatter;
                  return (
                    <BlogCard
                      key={uuidv4()}
                      img={cover}
                      title={title}
                      tag={keywords}
                      link={`blogs${node.fields.slug}`}
                      postDate={dayjs(date).format('DD MMM, YYYY')}
                      desc={node.excerpt}
                    />
                  );
                })
              )}
            </BlogsGrid>
          </div>
        </div>
      </div>
    </BlogsWrapper>
  );
};

BlogCard.propTypes = {
  tag: PropTypes.string,
  postDate: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  link: PropTypes.string,
};

export default index;
