import React from 'react';
import PropTypes from 'prop-types';

import Title from '../Title';
import {
  ServicesWrapper,
  ServiceGrid,
  Card,
  CardImgBox,
  CardBody,
  CardTitle,
  CardPara,
} from './style';

const CardComponent = (props) => {
  return (
    <Card>
      <CardImgBox>
        <img
          src={props.illustration}
          width="320"
          height="248"
          alt={`illustration of ${props.cardtitle}`}
        />
      </CardImgBox>

      <CardBody>
        <CardTitle> {props.cardtitle} </CardTitle>
        <CardPara>{props.carddesc}</CardPara>
      </CardBody>
    </Card>
  );
};

const Services = () => {
  const companyServices = [
    {
      id: '01',
      title: 'Mobile and Web Development',
      desc: 'Our experienced frontend team offers cutting-edge web development with modern frameworks like React, Ionic, SwiftUI, GraphQL, Vue, and more. On the backend, our server-side engineers have created hundreds of projects in Python, Ruby, NodeJS, PHP, and ASP.net. We have everything you need to create a robust, performant website or app.',
      illustration: '/img/services-mobile.svg',
    },
    {
      id: '02',
      title: 'Design Services',
      desc: "Performance isn't everything: beautiful design engages your visitors and multiplies your ROI. To that end, our exceptionally talented designers can help your business or brand create a signature design that lasts. Our team designs effective, aesthetically pleasing user interfaces that are simple and easy to navigate. We also offer tertiary design services like UX research, information architecture, usability assessments, and more.",
      illustration: '/img/services-design.svg',
    },
    {
      id: '03',
      title: 'ERP, Content Management',
      desc: "As your business grows in complexity, manually tracking resources and business activities can become overwhelming. Our team can help you implement custom Enterprise Resource Planning (ERP) systems that improve efficiency and standardize internal resources. To that end, we've worked with dozens of billion-dollar companies to design solutions for both private and public-facing websites, including custom ERPs and WordPress.",
      illustration: '/img/services-content.svg',
    },
  ];

  return (
    <ServicesWrapper>
      <div className="container">
        <Title
          title="What We Do"
          desc="Optimum Futurist provides a high-quality IT consulting service that seamlessly integrates into your company's existing workflow. We're efficient and attentive, and our team has helped hundreds of small and large businesses improve their digital presence."
          align="center"
        />

        <ServiceGrid>
          {companyServices.map(({ id, title, desc, illustration }) => {
            return (
              <CardComponent
                key={id}
                illustration={illustration}
                cardtitle={title}
                carddesc={desc}
              />
            );
          })}
        </ServiceGrid>
      </div>
    </ServicesWrapper>
  );
};

CardComponent.propTypes = {
  illustration: PropTypes.string,
  cardtitle: PropTypes.string,
  carddesc: PropTypes.string,
};

export default Services;
